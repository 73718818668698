// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer{
    background-color: var(--container-color);
    border-top: 1px solid rgba(0, 0 ,0, 0.1)
}

.footer__container{
    padding: 1rem 0 1rem;
}

.footer__title,
.footer__link{
    color: var(--title-color);
}

.footer__title{
    text-align: center;
    margin-bottom: var(--mb-1);
}

.footer__link:hover{
    color: var(--title-color-dark);
}

.footer__list{
    display: flex;
    justify-content: center;
    column-gap: 1.5rem;
    margin-bottom: var(--mb-2);
}

.footer__social{
    display: flex;
    justify-content: center;
    column-gap: 1.125rem;
}

.footer__social-icon{
    background-color: var(--title-color);
    color: var(--container-color);
    font-size: 1.125rem;
    padding: 0.4rem;
    border-radius: 0.5rem;
    display: inline-flex;
}

.footer__social-icon:hover{
background-color: var(--title-color-dark);
}

.footer__copy{
    display: block;
    margin-top: 2rem;
    color: var(--title-color);
    text-align: center;
    font-size: var(--smaller-font-size);
}

/*breakpoints*/
@media screen and (max-width: 992px){
    .footer__social-icon{
        padding: 0.25rem;
        border-radius: 0.25rem;
        font-size: 1rem;

    }

    }
`, "",{"version":3,"sources":["webpack://./src/components/footer/footer.css"],"names":[],"mappings":"AAAA;IACI,wCAAwC;IACxC;AACJ;;AAEA;IACI,oBAAoB;AACxB;;AAEA;;IAEI,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,0BAA0B;AAC9B;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,kBAAkB;IAClB,0BAA0B;AAC9B;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,oBAAoB;AACxB;;AAEA;IACI,oCAAoC;IACpC,6BAA6B;IAC7B,mBAAmB;IACnB,eAAe;IACf,qBAAqB;IACrB,oBAAoB;AACxB;;AAEA;AACA,yCAAyC;AACzC;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;IAClB,mCAAmC;AACvC;;AAEA,cAAc;AACd;IACI;QACI,gBAAgB;QAChB,sBAAsB;QACtB,eAAe;;IAEnB;;IAEA","sourcesContent":[".footer{\n    background-color: var(--container-color);\n    border-top: 1px solid rgba(0, 0 ,0, 0.1)\n}\n\n.footer__container{\n    padding: 1rem 0 1rem;\n}\n\n.footer__title,\n.footer__link{\n    color: var(--title-color);\n}\n\n.footer__title{\n    text-align: center;\n    margin-bottom: var(--mb-1);\n}\n\n.footer__link:hover{\n    color: var(--title-color-dark);\n}\n\n.footer__list{\n    display: flex;\n    justify-content: center;\n    column-gap: 1.5rem;\n    margin-bottom: var(--mb-2);\n}\n\n.footer__social{\n    display: flex;\n    justify-content: center;\n    column-gap: 1.125rem;\n}\n\n.footer__social-icon{\n    background-color: var(--title-color);\n    color: var(--container-color);\n    font-size: 1.125rem;\n    padding: 0.4rem;\n    border-radius: 0.5rem;\n    display: inline-flex;\n}\n\n.footer__social-icon:hover{\nbackground-color: var(--title-color-dark);\n}\n\n.footer__copy{\n    display: block;\n    margin-top: 2rem;\n    color: var(--title-color);\n    text-align: center;\n    font-size: var(--smaller-font-size);\n}\n\n/*breakpoints*/\n@media screen and (max-width: 992px){\n    .footer__social-icon{\n        padding: 0.25rem;\n        border-radius: 0.25rem;\n        font-size: 1rem;\n\n    }\n\n    }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
