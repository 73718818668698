import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin, faGithub, faMedium, faStrava } from '@fortawesome/free-brands-svg-icons';

const Social = () => {
  return (
    <div className="home__social">
      <a href="https://www.instagram.com/siddiq_odiq/" className="home__social-icon" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faInstagram} />
      </a>

      <a href="https://www.linkedin.com/in/odiq/" className="home__social-icon" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faLinkedin} />
      </a>

      <a href="https://github.com/siddiqodiq" className="home__social-icon" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faGithub} />
      </a>

     {/*  <a href="https://medium.com/@siddiqodiq" className="home__social-icon" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faMedium} />
      </a> */} 

      <a href="https://strava.app.link/0KEdrvXtpNb" className="home__social-icon" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faStrava} />
      </a>
    </div>
  )
}

export default Social;