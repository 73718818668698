import React, { useState } from 'react';
import './gallery.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const Gallery = () => {
  const images = [
    require("../../assets/Sertifikat/sertifikat (12).jpg"),
    require("../../assets/Sertifikat/sertifikat (1).jpg"),
    require("../../assets/Sertifikat/sertifikat (1).png"),    
    require("../../assets/Sertifikat/sertifikat (10).jpg"),
    require("../../assets/Sertifikat/sertifikat (11).jpg"),
    require("../../assets/Sertifikat/sertifikat (2).jpg"),
    require("../../assets/Sertifikat/sertifikat (2).png"),
    require("../../assets/Sertifikat/sertifikat (3).jpg"),
    require("../../assets/Sertifikat/sertifikat (4).jpg"),
    require("../../assets/Sertifikat/sertifikat (5).jpg"),
    require("../../assets/Sertifikat/sertifikat (6).jpg"),
    require("../../assets/Sertifikat/sertifikat (7).jpg"),
    require("../../assets/Sertifikat/sertifikat (8).jpg"),
    require("../../assets/Sertifikat/sertifikat (9).jpg"),
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const nextImage = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setIsTransitioning(false);
      }, 300);
    }
  };

  const prevImage = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
        setIsTransitioning(false);
      }, 300);
    }
  };

  return (
    <section className="gallery section" id="gallery">
      <h2 className="section__title">Certification</h2>
      <span className="section__subtitle">little bit about my experience</span>

      <div className="gallery__container container">
        <div className="gallery__content">
          <button 
            onClick={prevImage}
            className="gallery__nav gallery__nav-left"
            aria-label="Previous image"
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>

          <div className="gallery__img-container">
            <img 
              src={images[currentImageIndex]} 
              alt={`Certificate ${currentImageIndex + 1}`} 
              className={`gallery__img ${isTransitioning ? 'transitioning' : ''}`}
            />
          </div>

          <button 
            onClick={nextImage}
            className="gallery__nav gallery__nav-right"
            aria-label="Next image"
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      </div>
    </section>
  );
};

export default Gallery;

