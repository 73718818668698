import React, { useState, useEffect } from 'react';
import './about.css';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const About = () => {
  const images = [
    require("../../assets/karir.jpg"),
    require("../../assets/bola1.jpg"),
    require("../../assets/lari.jpg"),
    require("../../assets/bola2.jpg"),
    require("../../assets/lari2.jpg"),
    // Tambahkan path gambar lainnya di sini
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Ganti gambar setiap 3 detik

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <section className="about section" id="about">
      <h2 className="section__title">About Me</h2>
      <span className="section__subtitle">My Introduction</span>
      <div className="about__container container grid">
        <TransitionGroup className="about__img-container">
          <CSSTransition
            key={currentImageIndex}
            timeout={1000}
            classNames="fade"
            unmountOnExit
          >
            <img src={images[currentImageIndex]} alt="" className="about__img" />
          </CSSTransition>
        </TransitionGroup>
        <div className="about__data">
          <p className="about__description">
            Hello everyone, my name is Syubbanul Siddiq. I am a student from Indonesia. I have interest in cyber security, penetration testing, and software development. Sometimes in my free time, I like to build software and do other cool things like hacking, but in a good way✌️. My hobbies are playing football and running. I currently play for a soccer club called CFC from Bogor district. And I also have a dream to finish my first marathon someday...
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;